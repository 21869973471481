// Updated By Ayush.N

// helper fiels
import ColorsHelper from "../../../Helper/Colors";
import FontsHelper from "../../../Helper/Fonts";
import CommanStyles from "../../../Helper/CommanStyles";

const spCommanStyles = {
  btnStyle: {
    width: "8.7813rem",
    height: "3.75rem",
    ...FontsHelper.font500,
    borderRadius: "0.75rem",
    textTransform: "capitalize",
    fontSize: "1rem",
    textAlign: "center",
    letterSpacing: "0.025rem",
    ml: "0.75rem",
  },
};

const splStyles = {
  mainBox: {
    height: "100%",
    p: { xs: "0.8rem", sm: "1.3rem" },
  },
  spMainCompDiv: {
    ml: "auto",
    display: CommanStyles.dflex,
    justifyContent: "flex-end",
    maxWidth: "100%",
  },
  spMainCompMQDiv: { flexDirection: "column", overflowX: "auto", mx: "auto" },
  spTableBox: {
    marginTop: "1rem",
  },
  // add and edit splash page
  spaeMainBox: {
    width: "100%",
    display: CommanStyles.dflex,
    height: "100vh",
    flexDirection: { xs: "column", md: "row" },
    position: "relative",
  },
  spFormBox: {
    m: "auto",
    my: "1rem",
    maxWidth: { xs: "auto", md: "32rem", lg: "34rem" },
    px: { xs: "1rem", sm: "1.3rem" },
  },
  spPreMBox: {
    ...CommanStyles.flexContainer,
    width: { md: "22rem", xl: "30rem" },
    backgroundColor: ColorsHelper.blackClr,
    mt: { xs: "0", md: "0" },
    pt: { xs: "0", md: "0" },
  },
  spPreSB: {
    ...CommanStyles.flexColunmContainer,
    flexDirection: "column",
    minWidth: "20rem",
    backgroundColor: ColorsHelper.blackClr,
    py: "1rem",
    px: "1rem",
    overflow: "auto",
    height: "100%",
  },
  spPreT: {
    ...FontsHelper.font500,
    fontSize: "2rem",
    lineHeight: "2rem",
    textAlign: "center",
    color: ColorsHelper.whiteClr,
    cursor: "default",
  },
  spMB: {
    width: "100% ",
  },
  spPT: {
    ...FontsHelper.font500,
    fontSize: "2rem",
    lineHeight: "2rem",
    color: ColorsHelper.blackClr,
    cursor: "default",
  },
  spMTB: {
    mt: { xs: "0.5rem", md: "1rem" },
    mb: "0.8rem",
    px: { xs: "0.8rem", md: "1.3rem" },
  },
  spFieldTopDiv: {
    mt: "0.625rem",
  },
  spFieldBtnDiv: {
    width: "1.4375rem",
    height: "1.4375rem",
    borderRadius: "0.3125rem",
    cursor: "pointer",
  },
  spFPickDiv: {
    position: "absolute",
    top: "100%",
    left: "0",
    zIndex: "9999999999",
  },
  spFieldHeadDiv: {
    ...FontsHelper.font500,
    fontStyle: "normal",
    fontSize: "0.938rem",
    color: ColorsHelper.blackClr,
    cursor: "default",
  },
  spWBtn: {
    ...spCommanStyles.btnStyle,
    backgroundColor: ColorsHelper.whiteClr,
    color: ColorsHelper.blackClr,
    "&:hover": {
      backgroundColor: ColorsHelper.whiteClr,
      color: ColorsHelper.blackClr,
    },
  },
  spBBtn: {
    ...spCommanStyles.btnStyle,
    backgroundColor: ColorsHelper.blackClr,
    color: ColorsHelper.whiteClr,
    "&:hover": {
      backgroundColor: ColorsHelper.blackClr,
      color: ColorsHelper.whiteClr,
    },
  },
  spSpBtn: {
    backgroundColor: `${ColorsHelper.white7} !important`,
  },
  spWSpBtn: {
    backgroundColor: ColorsHelper.blackClr,
  },
  spBtnBox: {
    ...CommanStyles.flexEndIt,
    width: "100%",
    my: "1rem",
    px: { xs: "0.8rem", sm: "1.3rem" },
  },
  spTextTF: {
    marginTop: "0",
    marginBottom: "0.625rem",
  },
  spTogSwi: {
    width: "100%",
    display: { xs: "flex", md: "none" },
    justifyContent: "center",
    alignItems: "center",
    py: "1rem",
  },
  // spLblInfo: {
  //   mt: "0.625rem",
  //   width: "50%",
  //   ...CommanStyles.dflex,
  //   justifyContent: "space-between",
  // },
  spColPickB: {
    borderRadius: "0.3125rem",
    width: "2rem",
    height: "2rem",
    cursor: "pointer",
  },
  spCollPickSubB: {
    backgroundColor: ColorsHelper.whiteClr,
    height: "18.75rem",
    width: "18.75rem",
    margin: "0rem",
    padding: "0.8rem 0rem",
    borderRadius: "0.75rem",
    boxShadow: `0 0.625rem 1.25rem ${ColorsHelper.boxShadowOp2}, 0 0.375rem 0.375rem ${ColorsHelper.boxShadowOp3}`,
  },
  spColPickSubB: {
    width: "15rem",
    height: "15rem",
  },
  // mandatory Switch style
  spSwitch: {
    ...CommanStyles.dflex,
    justifyContent: "center",
    border: "0.063rem solid" + ColorsHelper.blackClr,
    width: "5.8125rem",
    borderRadius: "0.3rem",
    pl: "0.188rem",
    pr: "0.2505rem",
    py: "0.2505rem",
  },
  spASwi: {
    ...CommanStyles.centerIt,
    height: "1.875rem",
    width: "2.5rem",
    borderRadius: "0.313rem",
    cursor: "pointer",
    backgroundColor: ColorsHelper.blackClr,
    color: ColorsHelper.whiteClr,
    transition: "0.3s",
  },
  spInAwi: {
    ...FontsHelper.font500,
    fontSize: "0.75rem !important",
    transition: "0.3s",
    borderRadius: "0.313rem",
  },
  spASwiTxt: {
    ...FontsHelper.font500,
    fontSize: "0.75rem !important",
  },
  spInASwiTxt: {
    ...FontsHelper.font500,
    fontSize: "0.75rem !important",
    mt: "0.3125rem",
    mx: "0.625rem",
    display: "block",
    cursor: "pointer",
    color: ColorsHelper.blackClr,
  },
  toggleBtn: {
    p: "0.5rem 1rem",
    fontSize: "1rem",
    ...FontsHelper.font500,
    color: ColorsHelper.blackClr,
    border: "0.01rem solid" + ColorsHelper.blackClr,
    textTransform: "capitalize",
    "&.Mui-selected,&.Mui-selected:hover": {
      backgroundColor: ColorsHelper.blackClr + "!important",
      color: ColorsHelper.whiteClr + "!important",
    },
  },
  spFormCon: {
    overflow: "auto",
    width: "100%",
    height: { xs: "calc(90vh - 10rem)", sm: "calc(100vh - 9.5rem)" },
  },
  spColMenu: {
    mt: 4,
    div: {
      borderRadius: "0.875rem",
      li: {
        padding: "0",
        div: {
          bgcolor: "white",
        },
      },
    },
  },
  switch: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#000000",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#000000",
    },
  },
  sendOTPTxt: {
    ...FontsHelper.font500,
    fontSize: '0.9rem'
  }
};
export default splStyles;
