// Created By ND
// import React from 'react';
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


// helper functions
import urlsHelper from "./Helper/Urls";

// local files
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import sentryConfig from './Configurations/Sentry';

Sentry.init({
  dsn: urlsHelper.sentryServerUrl,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// root.render(<React.StrictMode><App /></React.StrictMode>);
root.render(<App urlsHelper={urlsHelper} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// // for sentry
// sentryConfig()
